@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
    box-sizing: border-box;
    white-space: pre-line; /* for \n in locals */
    padding: 0;
    outline: 0;
}
body {
    box-sizing: border-box;
    /*font-family: Montserrat Regular, Montserrat Light, "Droid Serif"; needs importing*/
    font: 15px Montserrat, sans-serif;
    line-height: 1.6;
    background: white;
}
.container {
    display: grid;
    grid-template-areas:
            'header header header header header header'
            'style style style style style style'
            'style style style style style style'
            'leftSide content content content content rightSide'
            'leftSide content content content content rightSide'
            'footer footer footer footer footer footer'
            'footer footer footer footer footer footer';
    grid-template-columns: 20% repeat(4, 1fr) 20%;
    grid-template-rows: 10% 50% auto 120% auto 40% auto;
    grid-gap: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.95;
    overflow: auto;
}
.container > div {
    border-radius: 2px;
}
.sidePDFs {
    grid-area: rightSide;
}
.header {
    grid-area: header;
}
.style {
    grid-area: style;
}
.content {
    grid-area: content;
    background-color: #f2f2f2;
}
.footer {
    grid-area: footer;
    background-color: dimgrey;
}
a {
    cursor: pointer;
}

@media screen and (min-width: 1900px) and (min-height: 900px) {
    .container {
        display: grid;
        grid-template-areas:
                'header header header header header header'
                'style style style style style style'
                'style style style style style style'
                'leftSide content content content content rightSide'
                'leftSide content content content content rightSide'
                'footer footer footer footer footer footer'
                'footer footer footer footer footer footer';
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 7% 50% auto 120% auto 40% auto;
        grid-gap: 0;
    }
}

@media screen and (max-width: 900px), (max-width: 400px) and (max-height: 750px) { /*regular mobile and smaller screen width*/

    .container {
        display: grid;
        grid-template-areas: '. header header header header .'
        '. style style style style .'
        '. style style style style .'
        '. content content content content .'
        '. content content content content .'
        '. footer footer footer footer .'
        '. footer footer footer footer .';
        grid-template-columns: 0 repeat(4, 1fr) 0;
        grid-template-rows: 15% 50% auto 120% auto 40% auto;
        grid-gap: 0;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 750px) and (max-height: 400px) { /*landscape mobile*/

    .container {
        display: grid;
        grid-template-areas:
                '. header header header header .'
                '. style style style style .'
                '. style style style style .'
                '. content content content content .'
                '. content content content content .'
                '. footer footer footer footer .'
                '. footer footer footer footer .';
        grid-template-columns: 0 repeat(4, 1fr) 0;
        grid-template-rows: 25% 60% auto 120% auto 40% auto;
        grid-gap: 0;
    }

}

/*
.container > div {
    background-color: #1EAAFC;
    background-image: linear-gradient(130deg, #171717 0%, #1EAAFC 85%, #3EDFD7 100%);
    color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); //6C52D9
    border: 3px solid #171717;
}
*/
