@import url('https://fonts.googleapis.com/css?family=Play:700&display=swap');

.container > .content, .infoPage {
    background: white;
    color: black;
    /*scroll-behavior: smooth;*/
    /*background-image: linear-gradient(130deg, whitesmoke 0%, ghostwhite 85%, white 100%);
    box-shadow: 0 3px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);*/
}
.content * {
    margin: 0;
}
.content .longBody {
    margin: 0 100px 30px 0;
    border-bottom: 2px solid ghostwhite;
    border-top: 2px solid ghostwhite;
}
.content .infoPage {
}
.content .infoPage .shortBody {
    color: black;
    margin: 0 100px 15px 0;
    line-height: 1.34;
}
.content .infoPage .sidePDFs {
    position: absolute;
    top: 55px;
    left: -85px;
    height: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.infoPage .sidePDFs h4 {
    color: #0073AE;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.sidePDFs div {
    font-family: Calibri, sans-serif;
    font-size: 10px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid rgba(248, 248, 255, 0.2);
    padding: 5px 0 3px 0;
    margin-bottom: 10px;
    line-height: 1.1;
    max-width: 80px;
    height: auto;
    letter-spacing: 1px;
    word-break: break-word;
}
.sidePDFs div a {
    width: 35px;
    height: 35px;
    background-image: url('images/pdf-icon-2.png');
    background-size: 100% 100%;
    margin-bottom: 5px;
}
.content .infoPage .currentOpenHeader {
    line-height: 1.1;
    padding-bottom: 25px;
    margin: 25px 100px 25px 0;
    border-bottom: 3px solid #85C446;
}
.content .infoPage .currentOpenHeader > h3 {
    font-size: 21px;
    font-weight: lighter;
}
.content .infoPage .currentOpenHeader > h2 {
    color: #0073AE;
    font-weight: normal;
    font-size: 25px;
}
.footer {
    opacity: 0.9;
    padding: 15px 15% 10px 15%;
    color: black;
    /*background-image: linear-gradient(130deg, black 0%, #112222 85%, darkslategrey 100%);*/
    box-shadow: 0 3px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
}
.upScroll {
    z-index: 9;
    position: fixed;
    bottom: 10vh;
    right: 7vh;
    background-color: whitesmoke;
    width: 30px;
    height: 27px;
    opacity: 0.7;
    border-radius: 5px;
    font-weight: bold;
    color: whitesmoke;
    border: 1px solid #0073AE;
    -webkit-padding-before: 3px;
    /*-moz-transform: scale(1.6,1.1);
    -webkit-transform: scale(2, 1.1);*/
    transform: scale(1.4,1.1);
    cursor: pointer;
    font-size: 25px;
    text-shadow: 1px 0 0 #0073AE, -1px 0 0 #0073AE, 0 1px 0 #0073AE, 0 -1px 0 #0073AE, 0.5px 0.5px #0073AE, -0.5px -0.5px 0 #0073AE, 0.5px -0.5px 0 #0073AE, -0.5px 0.5px 0 #0073AE;
    user-select: none;
}

/*-------------------------STYLE RELATED-----------------------------*/

.style {
    z-index: -8;
}
.style .innerStyle {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    z-index: -7;
    /*transition: background-position 500ms ease;*/
    /*filter: blur(5px) brightness(0) contrast(0.5);*/
}
.innerStyle .photos > * {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 400ms ease;

    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    filter: sepia(10%) saturate(95%);
}
.innerStyle .photos .activePhoto {
    opacity: 1.0;
    transition: opacity 500ms ease-out;
}
.innerStyle .photos .bg-house_1 {
    /*background-position: -10px -10px; from sprites..*/
    background-position: top center;
    background-image: url("./images/optimized/gunillantie_3_1680x981.jpg");
}
.innerStyle .photos .bg-house_2 {
    background-position: bottom center;
    background-image: url("./images/optimized/gunillantie_4_1680x981.jpg");
}
.innerStyle .photos .bg-house_3 {
    background-position: center 10%;
    background-image: url("./images/optimized/lonix_rakennus.jpg");
}
.innerStyle .photos .bg-house_4 {
    background-position: center 80%;
    background-image: url("./images/optimized/niittysilta_low_1680x981.jpg");
}
.innerStyle .photos .bg-house_5 {
    background-position: center 30%;
    background-image: url("./images/optimized/lonix_tower.jpg");
}

.style .innerStyle .photos .textDiv {
    font-family: Play, sans-serif;
    font-size: 30px;
    line-height: 1.1;
    color: whitesmoke;
    letter-spacing: 1.3px;
    user-select: none;
    margin: auto;
    max-width: 70%;
    opacity: 0;
    transform: translateY(20px);
    transition: transform 300ms ease, opacity 300ms ease;
    transition-delay: 400ms;
    text-shadow: 1.5px 0 0 black, -1.5px 0 0 black, 0 1.5px 0 black, 0 -1.5px 0 black, 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black;
}
.style .innerStyle .photos .activeText {
    opacity: 1.0;
    transform: translateY(0);
}
/*.run-animation {
    animation: floatText 4000ms ease-in 1;
    animation-fill-mode: forwards;
}
@keyframes floatText {
    10% {transform: translateY(0);
    }100% {transform: translateY(0);
    }
}*/
.styleToggle {
    width: 30px;
    height: 35px;
    align-content: center;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    transform: scale(1.5,1.8);
    font-size: 28px;
    z-index: -3;
    user-select: none;
}
.styleToggle > strong {
    text-shadow: 1px 0 0 black, -1px 0 0 black, 0 1px 0 black, 0 -1px 0 black, 0.5px 0.5px black, -0.5px -0.5px 0 black, 0.5px -0.5px 0 black, -0.5px 0.5px 0 black;
}
.style .innerStyle button[name="leftButton"] {
    position: absolute;
    bottom: 18px;
    right: 50px;
    user-select: none;
}
.style .innerStyle button[name="rightButton"] {
    position: absolute;
    bottom: 18px;
    right: 15px;
    user-select: none;
}

/*----------------------- PDF SPECIFIC----------------------------------*/

.pdf {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
    /*flex-flow: row wrap;*/
    align-items: flex-start;
    line-height: 1.1;
}
.pdf h2 {
    width: 100%;
    border-bottom: #85C446 3px solid;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: bolder;
}
.pdf table {
    border-spacing: 5px 5px;
    font-size: 13px;
    margin: 30px 0 15px 0;
    padding-left: 10px;
    width: 100%;
}
.pdf caption {
    text-align: left;
    font-size: 17px;
    color: black;
    padding-bottom: 5px;
}
.pdf tr, th, td{
    background-color: rgba(230,230,230,0.3);
    padding: 3px;
    height: 35px;
}
.pdf tr {
    width: 100%;
}
.pdf th {
    vertical-align: top;
    text-align: start;
    padding-left: 10px;
    padding-top: 5px;
    width: 30%;
    border: 2px solid rgba(230,230,230,0.5);
}
.pdf td {
    height: 100%;
    border: 2px solid rgba(230,230,230,0.5);
}
.pdf td:nth-child(2) {
    width: 51%;
}

.pdfLinkDL {
    padding: 0;
    width: auto;
    display: flex;
    flex-direction: row;
}
.pdfLinkDL a {
    text-decoration: none;
    color: black;
    margin-top: 10px;
}
.ImgPdf {
    filter: sepia(100%);
    height: 25px;
    width: 19px;
    margin: 4px 10px 0 5px;
}
.ImgDwg {
    filter: brightness(60%) sepia(100%);
    height: 28px;
    width: 28px;
    margin: 0 10px 0 5px;
}

.pdf .hiddenRow {
    display: none;
}
.pdf .missing {
    color: red;
}

.withPhoto {
    display: flex;
    flex-direction: row;
    margin: 20px 0 10px 0;
}
.withPhoto img {
    width: 49%;
    height: 50%;
    border-radius: 10px;
}
.withPhoto p {
    width: 49%;
    height: 100%;
}

/*-----------------SCREEN SIZE: IN-BETWEEN-----------------------------*/

@media screen and (max-width: 1450px) and (min-width: 900px) and (min-height: 500px) {

    .content .infoPage .sidePDFs {
        grid-area: leftSide;
        top: 55px;
        left: unset;
        right: 25px;
    }
    .logo {
        max-width: 240px;
        right: 5px;
        bottom: -20px;
    }
}

/*-----------------SCREEN SIZE: SMALL-----------------------------*/

@media screen and (max-width: 900px), (max-width: 400px) and (max-height: 750px) {

    .content {
        padding: 2px;
        width: 100%;
    }

    .content .longBody {
        margin: 20px 0 40px 5px;
        border-bottom: 1px solid #f2f2f2;
        border-top: 1px solid #f2f2f2;
    }

    .content .infoPage {
        padding-left: 5px;
    }

    .content .infoPage .shortBody {
        padding: 10px 10px 10px 0;
        margin: 0 0 0 0;
        color: black;
        opacity: 0.8;
    }

    .content .infoPage .infoPhoto {
        width: 100%;
        min-height: 100px;
    }

    .content .infoPage .currentOpenHeader {
        line-height: 1.2;
        padding-bottom: 20px;
        padding-left: 4px;
        margin: 20px 10px 10px 0;
    }

    .content .infoPage .currentOpenHeader > h3 {
        font-weight: normal;
        font-size: 16px;
    }

    .content .infoPage .currentOpenHeader > h2 {
        font-size: 20px;
    }

    .content .infoPage .sidePDFs {
        position: initial;
        top: initial;
        left: initial;
        height: auto;
        flex-flow: row wrap;
        justify-content: normal;
        width: auto;
        margin: 0 0 0 3px;
    }

    .infoPage .sidePDFs h4 {
        margin-bottom: 8px;
        width: 100%;
    }

    .sidePDFs div {
        max-width: 85px;
        padding: 0 5px 0 5px;
        margin: 0 10px 7px 0;
    }

    .sidePDFs div a {

    }

    .footer {
        opacity: 0.9;
        padding: 15px 20px 10px 20px;
    }

    .style .innerStyle .photos .textDiv {
        font-size: 23px;
        color: whitesmoke;
        margin: auto;
        padding: 0 0 0 15px;
        text-shadow: 1.2px 0 0 black, -1.2px 0 0 black, 0 1.2px 0 black, 0 -1.2px 0 black, 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black;
    }

    .style .innerStyle .styleToggle {
        width: 30px;
        height: 35px;
        align-content: center;
        background: transparent;
        border: none;
        color: white;
        font-size: 25px;
    }

    .style .innerStyle button[name="leftButton"] {
        position: absolute;
        bottom: 30%;
        right: 0;
    }

    .style .innerStyle button[name="rightButton"] {
        position: absolute;
        bottom: 60%;
        right: 0;
    }

    .content .longBody {
        padding: 0;
    }

    .contact .longBody > div {
        width: auto;
        padding: 10px;
        margin: 5px 5px 5px 0;
        background-color: #f2f2f2;
        color: black;
        background-image: linear-gradient(130deg, whitesmoke 0%, #f2f2f2 85%, ghostwhite 100%);
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
    }

    /*----------------------- PDF SPECIFIC ----------------------------------*/

    .pdf {
        display: flex;
        flex-direction: column;
        /*flex-flow: row wrap;*/
        align-items: flex-start;
        margin: 0 0 10px 0;
    }

    .pdf table {
        border-spacing: 3px 3px;
        font-size: 13px;
        margin: 15px 0 5px 0;
        width: 100%;
        padding-left: 0;
    }

    .pdf h2 {
        width: 100%;
        padding-bottom: 10px;
        font-size: 17px;
    }

    .pdf caption {
        text-align: left;
        font-size: 16px;
        color: black;
    }

    .pdf th {
        width: 27%;
        padding-left: 3px;
    }

    .pdf td:nth-child(2) {
        width: 45%;
    }

    .pdfLinkDL {
        width: auto;
        padding: 0;
    }

    .pdfLinkDL a {
        margin-top: 10px;
    }

    .ImgPdf {
        margin: 5px 7px 0 5px;
    }

    .ImgDwg {
        margin-top: 0;
        height: 25px;
        padding: 1px 0 0 3px;
        margin-right: 3px;
    }

    .withPhoto {
        display: flex;
        flex-direction: column-reverse;
        margin: 20px 0 40px 0;
    }

    .withPhoto img {
        width: 100%;
        height: auto;
    }

    .withPhoto p {
        margin-top: 0;
        padding: 15px 0 0 5px;
        width: 100%;
        height: 100%;
    }

    .upScroll {
        bottom: 4vh;
        right: 6vh;
    }
}

@media screen and (min-width: 1900px) and (min-height: 900px) {
    /*.style .innerStyle .photos > * {
        background-size: 250% 250%;
    }
    .innerStyle .photos .bg-lonix_town {
        background-position: -10px -10px;
    }
    .innerStyle .photos .bg-hakunila_2 {
        background-position: -2430px -10px;
    }
    .innerStyle .photos .bg-kaskelantie_1 {
        background-position: -10px -1011px;
    }
    .innerStyle .photos .bg-lonix_rakennus {
        background-position: -2430px -1011px;
    }
    .innerStyle .photos .bg-lonix_tower {
        background-position: -10px -2012px;
    }
    .innerStyle .photos .bg-gunillantie_4 {
        background-position: -2430px -2012px;
    }*/

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
}
