
.footer {
    position: relative;
    padding: 30px 5% 30px 17%;
}
.footerBody {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    font-size: 13px;
}
.footerBody div {
    margin: 5px 30px 10px 10px;
    color: lightgrey;
}
.footerBody h4 {
    margin: 0 0 0 0;
}
.footerBody ul {
    margin: 5px 0 0 0;
    list-style: none;
}
.footerBody ul li {
    margin: 2px;
}
.footerBody .first ul li::before, .second ul li::before {
    content: "\25A0";
    color: rgb(70, 70, 60);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.footerBody a {
    text-decoration: none;
    color: lightgrey;
}
.footerBody .fourth {
    margin: 10px 0 0 40px;
}
.footerBody .fourth div {
    margin-top: 20px;
}
.footerBody .siteCreator {
    position: absolute;
    bottom: 5px;
    right: 55px;
    font-size: 8px;
    margin: 20px 0 0 0;
    padding: 0;
}

@media screen and (max-width: 900px), (max-width: 400px) and (max-height: 750px) {

    .footer {
        padding: 25px 25px 25px 50px;
    }

    .footerBody {
        flex-flow: row wrap;
        justify-content: left;
    }

    .footerBody div {
        margin: 0 15px 15px 15px;
    }

    .footerBody .fourth {
        margin: 10px 0 0 0;
    }
}
