.header {
    display: grid;
    grid-template-areas:
            '. logo nav nav nav lang .';
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 0;
    /*background-image: linear-gradient(darkgrey 0%, silver 30%, ghostwhite 100%);*/
    background: url("./images/bg_gradient_gray.jpg") repeat-x;
    background-size: contain;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    user-select: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 4;
}
.header > a {
    grid-area: logo;
    position: relative;
}
.logo {
    position: absolute;
    bottom: -27px;
    right: 40px;
    visibility: visible;
    border-radius: 5px;
    height: auto;
    max-width: 280px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    /*filter: hue-rotate(13.142rad);*/
}
.logo2 {
    display: none;
}
.nav {
    grid-area: nav;
    list-style-type: none;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.1;
}
.nav .primaryLinks {
    display: flex;
    flex-flow: row nowrap;
    font-size: 16px;
    font-weight: lighter;
    padding-top: 10px;
    min-width: 250px;
    margin-left: 0;
}
.nav .primaryLinks > li {
}
.nav .primaryLinks > li > a {
    transition: all 0.1s ease-in;
    padding: 8px;
    text-decoration: none;
    color: rgb(70, 70, 60);
}
.nav .primaryLinks > li > a:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.nav .secondaryLinks {
    display: flex;
    flex-flow: row nowrap;
    font-size: 12px;
    font-weight: lighter;
    padding-top: 10px;
    min-width: 250px;
    margin-left: 40px;
}
.nav .secondaryLinks > li {
}
.nav .secondaryLinks > li > a {
    transition: all 0.1s ease-in;
    padding: 6px;
    text-decoration: none;
    color: rgb(70, 70, 60);
}
.nav .secondaryLinks > li > a:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.active {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.header .langButtons {
    width: 75px;
    height: 32px;
    grid-area: lang;
    position: absolute;
    top: 0;
    right: -100px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 5px;
    list-style-type: none;
    z-index: 1;
}
.header .langButtons button {
    border: 1px solid darkgrey;
    display: inline-block;
    width: 27px;
    height: 18px;
    font-size: 0;
    margin: 5px;
}
.langButtons button:hover, .langButtons button:active {
    border: 1px solid #f2f2f2;
}
.header button[name="fin"]{
    background: url("./images/fin_logo.bmp");
    background-size: 100% 100%;
}
.header button[name="eng"]{
    background: url("./images/eng_logo.bmp");
    background-size: 100% 100%;
}
.mobileMenuButton {
    display: none;
}
.subNavLinks {
    z-index: 15;
}
.subNav {
    position: fixed;
    width: auto;
    height: auto;
    border-radius: 5px;
    background: url("./images/bg_gradient_gray2.jpg") repeat-x;
    background-size: contain;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    user-select: none;
    border: 1px solid #fff;
    list-style-type: none;
    padding: 1px;
    /*font-family: "Calibri", Times, sans-serif;*/
}
.subNavLink {
    background: Transparent;
    transition: all 0.3s ease-out;
    border: none;
    cursor: pointer;
    padding: 2px;
    border-radius: 5px;
    display: block;
    line-height: 1.1;
    text-align: center;
}
.subNavLink:hover {
    background: #f2f2f2;
}
.subNavLink > a {
    color: black;
    text-decoration: none;
    font-size: 17px;
}
.subNavLink > a > * {
    padding: 15px 10px 15px 15px;
}

@media screen and (max-width: 900px), (max-width: 400px) and (max-height: 750px) {

    .header {
        display: grid;
        grid-template-areas:
                'logo logo logo . . . menu';
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 2px;
    }
    .mobileMenuButton {
        grid-area: menu;
        display: unset;
        padding: 15px;
        border: none;
        visibility: hidden;
    }
    .nav {
        display: none;
        height: auto;
        padding: 1px;
    }
    .toggle .nav {
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        top: 60px;
        right: 2px;

        background-color: #1EAAFC;
        background-image: linear-gradient(darkgrey 0%, silver 30%, ghostwhite 100%);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        border: 2px solid #fff;
    }
    .nav .primaryLinks {
        float: bottom;
        font-size: 14px;
        margin: 10px;
        padding-top: 0;
    }
    .nav .primaryLinks > li {
        display: inline-block;
    }
    .nav .primaryLinks > li > a {
        text-decoration: none;
        padding: 10px 15px 10px 15px;
        color: black;
        transition: all 0.3s ease-in;
    }
    .nav .primaryLinks > li > a:hover {
        box-shadow: none;
        background: #f2f2f2;
    }
    .nav .secondaryLinks {
        float: top;
        font-size: 12px;
        margin: 7px 7px 15px 7px;
    }
    .nav .secondaryLinks > li {
        display: inline-block;
    }
    .nav .secondaryLinks > li > a {
        text-decoration: none;
        padding: 5px;
        color: black;
        transition: all 0.3s ease-in;
    }
    .nav .secondaryLinks > li > a:hover {
        box-shadow: none;
        background: #f2f2f2;
    }
    .header .langButtons {
        visibility: hidden;
        position: absolute;
        top: 2px;
        right: 18%;
        z-index: 1;
    }
    .toggle .langButtons {
        visibility: visible;
    }
    .active {
        box-shadow: none;
        border-top: none;
        border-bottom: none;
    }
    .header > a {
        grid-area: logo;
        z-index: -1;
        display: flex;
        align-items: center;
    }
    .logo {
        display: none;
    }
    .logo2 {
        display: block;
        visibility: visible;
        border-radius: 0;
        box-shadow: unset;
        opacity: 0.9;
        z-index: -1;
        max-width: 255px;
        height: auto;
    }
    .subNavLinks {
        z-index: 11;
    }
    .subNav {
        position: fixed;
        width: auto;
        height: auto;
        border-radius: 2px;
        background-image: linear-gradient(darkgrey 0%, silver 30%, ghostwhite 100%);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        user-select: none;
        border: 1px solid #fff;
        padding: 1px;
        max-height: 100%;
        min-width: 220px;
        overflow-y: scroll;
    }
    .subNavLink {
        background: Transparent;
        transition: all 0.3s ease-in;
        border: none;
        cursor: pointer;
    }
    .subNavLink:hover {
        background: #f2f2f2;
    }
    .subNavLink > a {
        color: black;
        text-decoration: none;
    }
    .subNavLink > a > * {
        padding: 15px 2px 10px 2px;
    }

    /*----------------------Mobile Menu Button------------*/

    .bar1, .bar2, .bar3 {
        width: 35px;
        height: 5px;
        background-color: black;
        margin: 6px 0;
        transition: 0.4s;
        pointer-events: none;
        visibility: visible;
    }

    .toggle .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
    }

    .toggle .bar2 {opacity: 0;}

    .toggle .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
    }
}

@media screen and (min-width: 1900px) and (min-height: 900px) {

    .logo {
        bottom: -27px;
        right: 170px;

    }
}

@media screen and (max-width: 750px) and (max-height: 400px) {

    .logo2 {
        /*left: -120px;
        margin-top: 25px;
        margin-left: 0;*/
    }
}

