
/*-----------------PAGE SPECIFIC & RELATED--------------------------*/

.content > div {
    animation-name: contentOpacity;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}
@keyframes contentOpacity {
    0% {opacity: 0.05;}
    100% {opacity: 1.0;}
}
.content .longBody h2 {

}
.content .longBody h3 {
    margin: 15px 0 0 0;
}
.pathLocation {
    border-bottom: 1px lightgrey solid;
}
.pathLocation ul {
    list-style-type: none;
    padding: 10px 7px 10px 0;
}
.pathLink {
    color: black;
    font-size: 12px;
}

/*-----------------------HOME PAGE----------------------------------*/

.content .home .longBody {
    margin: 0 0 30px 0;
    padding: 0 0 10px 0;
    display: flex;
    flex-direction: column;
}
.content .home .longBody .withPhoto {
    margin: 0 0 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 20px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
}
.content .home .longBody .withPhoto img {
    max-width: 35%;
    height: auto;
    max-height: 100%;
    margin-top: 0;
    border-radius: 4px;
}
.content .home .longBody .withPhoto p {
    width: 65%;
    height: auto;
}
.content .home .longBody .withPhoto p strong {
    font-size: 17px;
}
.home .withPhoto img:first-child {
    box-shadow: 0 3px 8px rgba(0,0,0,0.29), 0 2px 4px rgba(0,0,0,0.43);
}
.home .withPhoto img:last-child {
    box-shadow: 0 3px 8px rgba(0,0,0,0.29), 0 2px 4px rgba(0,0,0,0.43);
}
.home .withPhoto p:last-child {
    padding-left: 30px;
}
.home .withPhoto p:first-child {
    padding-right: 30px;
}

.content .home .infoPage {
    padding: 0;
}
.content .home .infoPage .shortBody {
    display: none;
}
.content .home .infoPage .currentOpenHeader {
    margin-right: 0;
}

/*-------------------------NEWS PAGE----------------------------------*/

.content .news .longBody .newsArticle  {
    display: block;
    height: auto;
    margin: 30px 150px 50px 0;
}

.content .news .longBody .newsArticle .newsDate {
    line-height: 1.2;
    font-size: 13px;
}
.content .news .longBody .newsArticle h3 {
    border-bottom: dotted black 2px;
    margin: 0 0 10px 0;
    font-size: 17px;
}

.content .news .longBody .newsArticle .newsLink {
    margin: 15px 0 3px 0;
}

.content .news .longBody .newsArticle .newsLink a {
    text-decoration-line: none;
}

.content .news .longBody .newsArticle .newsLink > * {
    display: block;
}

/*-----------------------IMAGE + SIDETEXT GENERAL--------------------*/

.wrapper .longBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: none;
}
.wrapper .longBody > div {
    width: 100%;
}
.wrapper .longBody li {
    list-style-type: none;
    margin: 10px;
    padding: 10px;
    width: 100%;
    height: auto;
    font-size: 17px;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
}
.wrapper .longBody aside {
    background-image: linear-gradient(130deg, whitesmoke 0%, ghostwhite 85%, white 100%);
    box-shadow: 0 3px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
    border-radius: 10px;
    width: 460px;
    min-height: 220px;
    margin-left: 45px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    position: relative;
    line-height: 1.3;
    font-size: 14px;
}
.wrapper .longBody aside h4 {
    margin-bottom: 15px;
    font-size: 16px;
}
.wrapper .longBody aside span {
    margin-bottom: 10px;
}
.wrapper .longBody li > a {
    border-radius: 10px;
    background-clip: border-box;
    text-decoration: none;
    cursor: pointer;
    width: 250px;
    max-height: 210px;
    background-repeat: no-repeat;
    background-size: contain, 100% 100%;
    background-position: center;
}
.wrapper .longBody li > a:hover {
    box-shadow: 0 0 2px 1px grey;
}
.wrapper .longBody li > img {
    transition: padding 0.2s;
    transition-timing-function: cubic-bezier(.07,1.41,.82,1.41);
    width: 250px;
    height: auto;
    max-height: 210px;
}
.wrapper .longBody li > img:hover {
    padding: 10px;
}

.wrapper .longBody aside div {
    margin-top: 5px;
}
.wrapper .longBody aside div > .caseLink {
    text-decoration-line: none;
    font-size: 13px;
}
.wrapper .longBody aside > .vsLink {
    width: auto;
    max-width: 150px;
    height: 25px;
    border-radius: 7px;
    background-image: linear-gradient(darkgreen 0%, green 5%, #85C446 95%, greenyellow 100%);
    color: whitesmoke;
    border: unset;
    margin-top: 20px;
    text-decoration-line: none;
    text-align: center;
    padding-top: 3px;
}

/*------------------------METERING----------------------------------*/

.content .metering .pdf th:nth-child(1) {
    display: none;
}

.content .metering .pdf td:nth-child(2) {
    width: 60%;
    font-weight: bold;
}


/*------------------------PRODUCTS----------------------------------*/

.products .longBody .pSoftware li > a {
    background-image: url('images/sw_server.jpg');
}


.products .longBody .pMetering li > a {
    background-image: url('images/UNICOcoder-MP-kalt.png');
}

.products .longBody .pAutomation li > a {
    background-image: url('images/tmbAuto.jpg');
}

.products .longBody .pSecurity li > a {
    background-image: url('images/security.jpg');
}


/*----------------------Security----------------------*/

.security .longBody .pComponents > a {
    background-image: url('images/tmbSecComp.jpg');
}

.security .longBody .pAccesscontrolsystem > a {
    background-image: url('images/v100.jpg');
}

.security .longBody .pVideosurveillance > a {
    background-image: url('images/video_monitoring.jpg');
}


/*----------------------VIDEO SURVEILLANCE----------------------*/



/*-----------------------CONTACT--------------------------------*/

.contact .longBody > div {
    width: 60%;
    color: black;
    opacity: 0.7;
    padding: 5px 15px 20px 20px;
    margin: 15px 15px 15px 0;
    background-color: rgba(230,230,230,0.7);
    border: 2px solid rgba(230,230,230,0.7);
    /*background-image: linear-gradient(130deg, whitesmoke 0%, #f2f2f2 85%, ghostwhite 100%);*/
    box-shadow: 0 3px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
}

/*------------------------SUPPORT--------------------------------*/



@media screen and (max-width: 900px), (max-width: 400px) and (max-height: 750px) {

    .pathLocation {
        margin-left: 5px;
    }

    .wrapper .longBody {
        padding: 0 0 10px 0;
        margin: 10px 0 0 0;
        display: flex;
        flex-direction: column;
    }

    .wrapper .longBody div {
        margin-bottom: 5px;
    }

    .wrapper .longBody li {
        list-style-type: none;
        margin: 5px 5px 5px 0;
        width: auto;
        height: auto;
        display: flex;
        flex-flow: row;
        padding: 1px;
    }

    .wrapper .longBody aside {
        background-image: linear-gradient(130deg, whitesmoke 0%, ghostwhite 85%, white 100%);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19), 0 1px 1px rgba(0, 0, 0, 0.23);
        border-radius: 3px;
        width: 60%;
        min-height: unset;
        margin-left: 4px;
        padding: 10px;
        font-size: 12px;
    }

    .wrapper .longBody aside h4 {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .wrapper .longBody aside > .vsLink {
        height: 20px;
        padding-top: 2px;
        margin-top: 5px;
    }

    .wrapper .longBody li > a {
        width: 40%;
        max-height: 190px;
    }

    .wrapper .longBody li > img {
        object-fit: contain;
        max-height: 190px;
        width: 40%;
        height: auto;
    }

    .contact .longBody {
        margin-top: 0;
        padding-left: 20px;
    }

    .contact .longBody > div {
        width: 90%;
        color: black;
    }

    .content .home .longBody {
        margin: 0 0 30px 0;
        padding: 0 0 10px 3px;
        display: flex;
        flex-direction: column;
    }

    .content .home .longBody .withPhoto {
        margin: 0 0 10px 0;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding: 5px 5px 0 0;
        box-shadow: none;
        position: relative;
        height: auto;
        overflow: visible;
    }

    .content .home .longBody .withPhoto img {
        margin: 0 10px 15px 0;
        width: auto;
        height: auto;
        max-height: 350px;
        max-width: 100%;
        border-radius: 1px;
        transform: scale(1, 1);
    }

    /*.content .home .longBody .withPhoto:first-child::after {
        content: "";
        height: 0;
    }
    .content .home .longBody .withPhoto:last-child::before {
        content: "";
        height: 0;
    }*/

    .content .home .longBody .withPhoto img:last-child {
        /*position: absolute;
        left: 0;
        bottom: 0;
        height: 400px;*/
        display: none;
    }

    .content .home .longBody .withPhoto img:first-child {
        /*position: absolute;
        top: -410px;
        left: 50%;*/
    }

    .content .home .longBody .withPhoto p {
        width: 100%;
        height: auto;
        padding: 5px 2px 10px 2px;
    }

    .content .home .infoPage {
        padding: 0;
    }

    .content .home .infoPage .shortBody {
        display: none;
    }

    .content .home .infoPage .currentOpenHeader {
        margin-right: 0;
    }

    .content .news .longBody {
        margin-top: 0;
    }

    .content .news .longBody .newsArticle {
        margin: 15px 10px 50px 5px;
    }

}

@media screen and (min-width: 1900px) and (min-height: 900px) {

    .content .home .longBody {
        justify-content: center;
    }
    .content .home .longBody .withPhoto {
        margin: 0 0 30px 0;
        height: 430px;
    }
    .content .home .longBody .withPhoto img {
        width: 35%;
        height: auto;
        max-height: 100%;
    }

    .content .home .longBody .withPhoto p {
        width: 65%;
        height: auto;
    }

}
